.fullProfile {
    
    animation: fade .9s ease-in;
    margin: auto;
    align-content: center;
    width: 100%;
    background-color: whitesmoke;
    height: 25rem;
}

.monk-wrapper img {
    width: 15rem;
    height: 18rem;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 100px;
    display: inline-block;
}

.monk-wrapper {
    width: 100%;
    margin: auto;
}

.infoWrapper {
    width: 30rem;
    display: inline-block;
}

.bioP p {
    text-align: left;
    font-size: 1.1rem;
}
.bioP {
    padding-left: 2rem;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
}

@media only screen and (max-width:816px) {
    .fullProfile {
        height: 45rem;
    }
}