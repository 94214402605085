@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,400;1,200&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: Raleway, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #D8DBE2;
  margin: auto;
}

.header {
  /* background-color: #A9BCD0; */
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 100;
}

.backer {
  background-image: url("./components/pics/back.jpg");
  width: 100%;
  opacity: .8;
  position: absolute;
  top: 0;
  height: 3rem;
}



a:visited {
  color: black;
}

a {
  color: black;
}



.mainPage {
  width: 100%;
  margin: auto;
}

p {
  font-size: 1.3rem;
  font-family: Raleway, sans-serif;
  padding: 0 1rem 1rem 1rem;
}

.card-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  align-content: center;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.card-container {
  margin: auto;
  align-content: center;
  padding-top: 1rem;
}

@media only screen and (max-width: 860px) {
  .card-wrapper {
    grid-template-columns: 1fr;
  }
}

.contact-wrapper {
  background-color: white;
}
