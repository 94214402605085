h2, h3 {
    display: inline-block;
    padding-right: 2rem;
}

.inner-contact-wrapper {
    width: 28rem;
    padding-left: 5rem;
    text-align: left;
    margin: auto;
    border-radius: 50px;
}

.contact-wrapper {
    background-image: url("./pics/valley2.png");
    background-size: auto;
    background-repeat: no-repeat;
}

.contact-backer {
    padding: 1rem;
}

.contact-backer2 {
    padding: 1rem;
    opacity: .9;
    border-radius: 75px;
}

.contact-backer2 h2,h3 {
    opacity: 1;
}