.Resume {
    height: 50rem;
    background-image: url("../components/pics/valley.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.Resume h1 {
    color: black;
}

.resumeContainer {
    padding-top: 15rem;
    width: 30rem;
    margin: auto;
    align-content: center;
    animation: fade 1.5s ease-in;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
}

.resumeBacker {
    background-image: url("./pics/blue.jpg");
    width: 6rem;
    height: 3rem;
    position: absolute;
    top: 29.5rem;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .4;
    border-radius: 25px 45px;
}

.resumeBlock {
    position: relative;
    padding-left: 2rem;
}