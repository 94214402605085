.card-container {
    width: 15rem;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    transition: 0.5s;
    animation: ease-in-out;
    border-radius: 25px;
}

.card-container:hover {
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
}

.image-container {
    overflow: hidden;
    height: 13rem;

}

.card-content {
    margin: 1rem;
    margin-top: .5rem;
}

.card-container h3,p { 

    /* resetting default value */
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
}

.card-container p {
    font-size: 1rem;
}

.card-container img {
    width: 90%;;
    height: 100%;
    border-radius: 100rem;;
}

.card-title {
    margin-bottom: .5rem;;
}

.btn {
    display: flex;
    justify-content: center;

}

button {
    padding: .5rem;
    background-color: #D8DBE2;
    border-style: none;
    transition: .2s;
    border-radius: 25px;
    margin-top: .5rem;
}

button:hover {
    background: rgba(27,156,252, .1);
    border-radius: 25px;
}

.card-container a {
    font-weight: bold;
    color: darkcyan;
    font-family: Raleway, sans-serif;
}

.card-wrap {
    padding: 1rem;
}